import React, { useEffect } from 'react'
import { breakpoints, ContentContainer, HideOverflow } from 'src/utils/styles/global-styles'

import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import { CAL_MAX_BUY_LINK } from 'src/utils/constants'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import FAQs from '../components/FAQs'
import SEO from '../components/seo'
import CalMaxHero from '../components/CalMaxHero'
import { CenteredContent, BuyNowLink } from '../components/Hero/styles'
import Analytics from '../utils/Analytics'
import CalendarPress from '../components/CalendarPress'
import CalendarMatrix from '../components/CalendarMatrix'
import CalendarGuarantee from '../components/CalendarGuarantee'
import RedesignCalendarTestimonials from '../components/RedesignCalendarTestimonials'
import CalMaxFeatures from '../components/CalMaxFeatures'
import Counter from '../components/Counter'
import CalendarMaxFeatures from '../components/CalendarMaxFeatures'

const CalendarMaxPage = ({ data: { calendarOgImage, calendarInHome, calendarInHomeMobile } }) => {
  const { t } = useTranslation('calendar')

  useEffect(() => {
    Analytics.track('Viewed Calendar Max Landing Page')
  }, [])

  return (
    <>
      <SEO
        title={t('Smart Touchscreen Family Calendar and Organizer | Skylight Calendar')}
        description={t(
          'Skylight Calendar Max (27”) is a Smart Touchscreen Calendar and Family Organizer that serves as the hub of the home. Built for wall mounting only, Calendar Max can be affixed to the wall in landscape or portrait mode.'
        )}
        imagePath={calendarOgImage.childImageSharp.gatsbyImageData.images.fallback.src}
        path="/calmax/"
      />
      <Helmet>
        <meta charset="UTF-8" />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css"
        />
      </Helmet>
      <CalMaxHero />
      <Counter />
      <CalMaxFeatures />
      <CalendarGuarantee
        first
        title="Limited quantities available"
        description="Preorder now to secure your spot as one of the first to receive Cal Max when we start shipping in September and October 2024"
        ctaText="Preorder now"
        ctaLink={CAL_MAX_BUY_LINK}
      />
      <CalendarinHome>
        <CalendarInHome
          image={calendarInHome.childImageSharp.gatsbyImageData}
          alt="Calendar max displayed on wall in kids room"
        />
        <CalendarInHomeMobile
          image={calendarInHomeMobile.childImageSharp.gatsbyImageData}
          alt="Calendar max displayed on wall in kids room"
        />
        <HomeText>
          Designed for your home. <br />
          Built for your family.
        </HomeText>
      </CalendarinHome>
      <CalendarMaxFeatures />
      <HideOverflow>
        <RedesignCalendarTestimonials />
      </HideOverflow>
      <CalendarPress />
      <CalendarGuarantee
        title="Limited quantities available"
        description="Preorder now to secure your spot as one of the first to receive Cal Max when we start shipping in September and October 2024"
        ctaText="Preorder now"
        ctaLink={CAL_MAX_BUY_LINK}
      />
      <CalendarMatrix />
      <FAQs page="calMax" />
      <ContentContainer>
        <CenteredContent>
          <BuyNowLink to={CAL_MAX_BUY_LINK}>
            <Trans>Preorder now</Trans>
          </BuyNowLink>
        </CenteredContent>
      </ContentContainer>
    </>
  )
}

CalendarMaxPage.propTypes = {
  data: PropTypes.shape({
    calendarOgImage: PropTypes.object.isRequired,
    calendarInHome: PropTypes.object.isRequired,
    calendarInHomeMobile: PropTypes.object.isRequired,
  }).isRequired,
}

export const CalendarInHome = styled(GatsbyImage)`
  display: block;
  @media (max-width: ${breakpoints.m}px) {
    display: none;
  }
`

export const CalendarInHomeMobile = styled(GatsbyImage)`
  display: none;
  @media (max-width: ${breakpoints.m}px) {
    display: block;
  }
`

export const CalendarinHome = styled.div`
  width: 100%;
  position: relative;
`
export const HomeText = styled.p`
  color: #fff;
  text-align: center;
  text-shadow: 0px 1px 6px rgba(98, 98, 98, 0.25);
  font-family: 'FilsonPro';
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.72px;
  margin: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  @media (max-width: ${breakpoints.xl}px) {
    font-size: 38px;
  }
  @media (max-width: ${breakpoints.m}px) {
    display: none;
  }
`

export default CalendarMaxPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "calendar"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    calendarOgImage: file(relativePath: { eq: "calmax/cal-max-seo.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    calendarInHome: file(relativePath: { eq: "calmax/designed-for-home.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    calendarInHomeMobile: file(relativePath: { eq: "calmax/designed-for-home-mobile.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
  }
`
