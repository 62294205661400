import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import YotpoStars from 'src/components/YotpoStars'
import {
  Section,
  JumbotronImageContainer,
  ContentContainer,
  Headline,
  HeadlineMobile,
  Title,
  Text,
  TextMobile,
  Disclosure,
  DisclosureMobile,
  Intro,
  IntroMobile,
  MobileContentContainer,
  CtaContianer,
  CalMaxHeroVideo,
  OverflowHidden,
  MobileTitle,
  CenteredMobile,
  BuyNowLink,
} from './styles'
import { CAL_MAX_BUY_LINK } from '../../utils/constants'
import calMaxVideo from '../../videos/cal-max-hero-mobile.mp4'

const CalMaxHeo = () => {
  const { calendarHeroImage } = useStaticQuery(graphql`
    query calMaxHeoQuery {
      calendarHeroImage: file(relativePath: { eq: "calmax/cal-max-hero.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  return (
    <>
      <Section>
        <MobileTitle>
          Max<span>imize Your Family Time.</span>
        </MobileTitle>
        <JumbotronImageContainer>
          <GatsbyImage
            image={calendarHeroImage.childImageSharp.gatsbyImageData}
            alt="Skylight Calendar Max displayed on wall"
            loading="eager"
          />
          <OverflowHidden>
            <CalMaxHeroVideo playsInline autoPlay muted loop>
              <source src={calMaxVideo} type="video/mp4" />
            </CalMaxHeroVideo>
          </OverflowHidden>
        </JumbotronImageContainer>
        <ContentContainer>
          <Headline>
            <YotpoStars color="#000" textColor="#444444" productId="6863425175604" />
          </Headline>
          <Intro>INTRODUCING CALENDAR MAX</Intro>
          <Title>
            Max<span>imize Your Family Time.</span>
          </Title>
          <Text>
            The best-selling smart family calendar is now available in a beautiful 27” touchscreen.
            Limited quantities available now for preorder.
          </Text>
          <CtaContianer>
            <CenteredMobile className="desktopCta">
              <BuyNowLink to={CAL_MAX_BUY_LINK}>Preorder now</BuyNowLink>
            </CenteredMobile>
            {/* <Disclosure>Est. delivery September 2024</Disclosure> */}
          </CtaContianer>
        </ContentContainer>
      </Section>
      <MobileContentContainer>
        <HeadlineMobile>
          <YotpoStars color="#000" textColor="#444444" productId="6863425175604" />
        </HeadlineMobile>
        <IntroMobile>Introducing the Skylight Calendar Max</IntroMobile>
        <TextMobile>
          The best-selling smart family calendar is now available in a beautiful 27” touchscreen.
          Limited quantities available now for preorder.
        </TextMobile>
        <CenteredMobile>
          <BuyNowLink to={CAL_MAX_BUY_LINK}>Preorder now</BuyNowLink>
        </CenteredMobile>
        {/* <DisclosureMobile>Est. delivery September 2024</DisclosureMobile> */}
      </MobileContentContainer>
    </>
  )
}

export default CalMaxHeo
