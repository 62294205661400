import React from 'react'

import all from 'src/images/calmax/all-in-one.svg'
import unite from 'src/images/calmax/calendars-unite.svg'
import plan from 'src/images/calendar/plan-anytime.svg'
import habit from 'src/images/calmax/habit-haven.svg'
import dinner from 'src/images/calmax/dinner-decided.svg'
import track from 'src/images/calmax/on-track.svg'
import allMobile from 'src/images/calmax/all-in-one-mobile.svg'
import uniteMobile from 'src/images/calmax/calendars-unite-mobile.svg'
import planMobile from 'src/images/calendar/plan-anytime-mobile.svg'
import habitMobile from 'src/images/calmax/habit-haven-mobile.svg'
import dinnerMobile from 'src/images/calmax/dinner-decided-mobile.svg'
import trackMobile from 'src/images/calmax/on-track-mobile.svg'

import {
  Row,
  ContentContainer,
  Header,
  Title,
  Text,
  MobileImageContainer,
  TextContainer,
} from './styles'

import { CAL_MAX_FEATURES } from '../CalendarNewFeatures/constants'

const CalMaxFeatures = () => {
  const features = [
    { d: all, m: allMobile, color: '#A6C9DF' },
    { d: unite, m: uniteMobile, color: '#F5C67A' },
    { d: plan, m: planMobile, color: '#BDBDBD' },
    {
      d: habit,
      m: habitMobile,
      color: '#7EB6B6',
    },
    {
      d: dinner,
      m: dinnerMobile,
      color: '#DCB68C',
    },
    {
      d: track,
      m: trackMobile,
      color: '#F3C6BC',
    },
  ]

  return (
    <ContentContainer>
      <Header>Designed to delight</Header>
      {CAL_MAX_FEATURES.map((feature, i) => (
        <Row key={i} color={features[i].color} reverse={i % 2 === 0}>
          <MobileImageContainer key={features[i]} type="image/svg+xml" data={features[i].d}>
            svg-animation
          </MobileImageContainer>
          <MobileImageContainer key={features[i]} type="image/svg+xml" data={features[i].m}>
            svg-animation
          </MobileImageContainer>
          <TextContainer extra={i === 1}>
            <Title>{feature.title}</Title>
            <Text>{feature.text}</Text>
          </TextContainer>
        </Row>
      ))}
    </ContentContainer>
  )
}

export default CalMaxFeatures
