export const CAL_MAX_FEATURES = [
  {
    title: 'Dream big',
    text: 'Displays every detail so you can look ahead without missing a beat today.',
  },
  {
    title: 'Fits your space',
    text: 'Wall-mount in portrait or landscape; the Calendar adjusts automatically.',
  },
  {
    title: 'Dynamic styles',
    text: 'Frame styles and finishes can easily be swapped to match your decor.',
  },
  {
    title: 'Vibrant display',
    text: 'The Quad HD display boasts high resolution of 2560x1440 pixels and is equipped with an anti-glare screen.',
  },
]
