import React from 'react'

import dreamBig from 'src/images/calmax/cal-max-dream-big.svg'
import dreamBigMobile from 'src/images/calmax/cal-max-dream-big-mobile.svg'
import vibrantDisplay from 'src/images/calmax/cal-max-vibrant-display.svg'
import vibrantDisplayMobile from 'src/images/calmax/cal-max-vibrant-display-mobile.svg'
import dynamicStyles from 'src/images/calmax/cal-max-dynamic-styles.svg'
import fitsYourSpace from 'src/gifs/fits-your-space-desktop.gif'
import fitsYourSpaceMobile from 'src/gifs/fits-your-space-mobile.gif'

import {
  Section,
  Row,
  ContentContainer,
  Header,
  Title,
  Text,
  MobileImageContainer,
  TextContainer,
  VideoContainer,
  VideoContainerMobile,
} from './styles'

import { CAL_MAX_FEATURES } from './constants'

const CalendarMaxFeatures = () => {
  const features = [
    { d: dreamBig, m: dreamBigMobile },
    { d: '', m: '', video: true },
    { d: dynamicStyles, m: dynamicStyles },
    {
      d: vibrantDisplay,
      m: vibrantDisplayMobile,
    },
  ]

  return (
    <Section>
      <ContentContainer>
        <Header>Take a closer look</Header>
        {CAL_MAX_FEATURES.map((feature, i) => (
          <Row key={i} color={features[i].color} reverse={i % 2 !== 0}>
            {features[i].video ? (
              <>
                <VideoContainer>
                  <img src={fitsYourSpace} alt="Skylight Calendar turns into a screensaver" />
                </VideoContainer>
                <VideoContainerMobile>
                  <img src={fitsYourSpaceMobile} alt="Skylight Calendar turns into a screensaver" />
                </VideoContainerMobile>
              </>
            ) : (
              <>
                <MobileImageContainer key={features[i]} type="image/svg+xml" data={features[i].d}>
                  svg-animation
                </MobileImageContainer>
                <MobileImageContainer key={features[i]} type="image/svg+xml" data={features[i].m}>
                  svg-animation
                </MobileImageContainer>
              </>
            )}
            <TextContainer>
              <Title>{feature.title}</Title>
              <Text>{feature.text}</Text>
            </TextContainer>
          </Row>
        ))}
      </ContentContainer>
    </Section>
  )
}

export default CalendarMaxFeatures
